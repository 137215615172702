import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'
import dynamic from 'next/dynamic'

import {
  SanityHero,
  SanityHeroBackgroundType,
  SanityHeroContentPosition,
} from '@data/sanity/queries/types/modules'
import { serializers } from '@lib/serializers'
import { getVimeoVideoId } from '@lib/video'

import Photo from '@components/photo'

const MuxVideo = dynamic(() => import('@components/video/mux-video'))
const VimeoVideoLoop = dynamic(
  () => import('@components/video/vimeo-video-loop')
)

type HeroContentProps = Pick<SanityHero, 'content' | 'contentPosition'>

type HeroProps = SanityHero & {
  overlay?: boolean
}

export const HeroContent = ({ content, contentPosition }: HeroContentProps) => {
  const center = contentPosition === SanityHeroContentPosition.CENTER
  const bottomLeft = contentPosition === SanityHeroContentPosition.BOTTOM_LEFT

  return (
    <div
      className={cx('container absolute inset-0 z-10 flex flex-col', {
        'justify-end': bottomLeft,
        'justify-center items-center': center,
      })}
    >
      {content && (
        <div
          className={cx('z-10 w-full text-pageBG mt-14', {
            'md:max-w-[70%] xl:max-w-[50%] mb-4 md:mb-10': bottomLeft,
            'lg:max-w-[55%]': center,
          })}
        >
          <BlockContent
            renderContainerOnSingleChild
            blocks={content}
            serializers={serializers}
            className={cx('rc rc-hero', {
              'text-center': center,
            })}
          />
        </div>
      )}
    </div>
  )
}

const Hero = ({
  content,
  contentPosition,
  bgType,
  photos,
  overlay,
  vimeoVideo,
  muxVideo,
  videoAspectRatio,
}: HeroProps) => {
  const vimeoVideoId = getVimeoVideoId(vimeoVideo?.link)

  return (
    <section
      className={cx('relative overflow-hidden bg-pageBG max-h-screen', {
        'before:absolute before:inset-0 before:z-[1] before:bg-pageText before:bg-opacity-50':
          overlay,
      })}
    >
      <HeroContent content={content} contentPosition={contentPosition} />

      <div>
        {bgType === SanityHeroBackgroundType.VIMEO_VIDEO &&
          vimeoVideo &&
          vimeoVideoId && (
            <VimeoVideoLoop title={vimeoVideo.name} id={vimeoVideoId} />
          )}

        {bgType === SanityHeroBackgroundType.MUX_VIDEO && muxVideo && (
          <MuxVideo
            video={muxVideo}
            showControls={false}
            autoplay={true}
            muted={true}
            loop={true}
            aspectRatio={videoAspectRatio}
          />
        )}

        {bgType === SanityHeroBackgroundType.PHOTO && photos && (
          <>
            {photos.desktopPhoto && (
              <Photo
                image={photos.desktopPhoto}
                layout="fill"
                objectFit="cover"
                priority={true}
                className="relative w-full z-0 h-full hidden sm:block"
                style={{
                  paddingTop: `${
                    100 / (photos.desktopPhoto.customRatio ?? 16 / 9)
                  }%`,
                }}
              />
            )}
            {photos.mobilePhoto && (
              <Photo
                image={photos.mobilePhoto}
                layout="fill"
                objectFit="cover"
                priority={true}
                className="relative w-full z-0 h-full sm:hidden"
                style={{
                  paddingTop: `${
                    100 / (photos.mobilePhoto.customRatio ?? 5 / 7)
                  }%`,
                }}
              />
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default Hero
