import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'
import { m, AnimatePresence } from 'framer-motion'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { SanityContentFragment } from '@data/sanity/queries/types/content'
import { SanityHeroCaseVideoCarousel } from '@data/sanity/queries/types/modules'
import { SanityMuxVideo } from '@data/sanity/queries/types/video'
import { fadeWithSlideAnimation } from '@lib/animate'
import { PageHeroContext } from '@lib/page-hero-context'
import { serializers } from '@lib/serializers'

import MuxVideo from '@components/video/mux-video'
import { HeroContent } from './hero'

type HeroCaseVideoCarouselProps = Pick<SanityHeroCaseVideoCarousel, 'items'>

interface CaseHeroContentProps {
  slideHeroContent?: Array<SanityContentFragment>
  isSlideTransition: boolean
  index: number
  activeIndex: number
}

interface HeroCaseVideoCarouselItemProps {
  slideHeroContent?: Array<SanityContentFragment>
  muxVideo?: SanityMuxVideo
  isSlideTransition: boolean
  index: number
  activeIndex: number
}

interface HeroCaseVideoCarouselDotProps {
  changeSlide: (index: number) => void
  index: number
  activeIndex: number
}

const slideDuration = 7000
const slideTransitionDuration = 1000

const CaseHeroContent = ({
  slideHeroContent,
  isSlideTransition,
  index,
  activeIndex,
}: CaseHeroContentProps) => {
  const { heroCaseVideoCarouselDisabled, caseHeroModule } =
    useContext(PageHeroContext)

  const isActive = activeIndex === index

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {/* Case carousel item content */}
      {!heroCaseVideoCarouselDisabled && (
        <m.div
          key={`case-hero-content-closed-${index}`}
          initial="hide"
          animate="show"
          exit="hide"
          variants={fadeWithSlideAnimation}
          className={cx('absolute inset-0', {
            'z-10': !isActive,
            'z-20': isActive,
          })}
        >
          <div
            className={cx(
              'px-4 sm:container absolute bottom-16 sm:bottom-32 lg:bottom-44 inset-x-0'
            )}
          >
            {slideHeroContent && (
              <BlockContent
                renderContainerOnSingleChild
                blocks={slideHeroContent}
                serializers={serializers}
                className={cx(
                  'rc md:max-w-[80%]',
                  `child:transition child:duration-1000`,
                  {
                    'child:opacity-0 child:translate-y-[20px]':
                      !isActive || isSlideTransition,
                    'child:opacity-100 child:translate-y-0':
                      isActive && !isSlideTransition,
                  }
                )}
              />
            )}
          </div>
        </m.div>
      )}

      {/* Case page hero content */}
      {heroCaseVideoCarouselDisabled && (
        <m.div
          key={`case-hero-content-open-${index}`}
          initial="hide"
          animate="show"
          exit="hide"
          variants={fadeWithSlideAnimation}
          className={cx('absolute inset-0', {
            'z-10': !isActive,
            'z-20': isActive,
          })}
        >
          {caseHeroModule?.content && (
            <HeroContent
              content={caseHeroModule.content}
              contentPosition={caseHeroModule.contentPosition}
            />
          )}
        </m.div>
      )}
    </AnimatePresence>
  )
}

const HeroCaseVideoCarouselItem = ({
  slideHeroContent,
  muxVideo,
  isSlideTransition,
  index,
  activeIndex,
}: HeroCaseVideoCarouselItemProps) => {
  const { heroCaseVideoCarouselDisabled } = useContext(PageHeroContext)

  const isActive = activeIndex === index

  if (heroCaseVideoCarouselDisabled && !isActive) {
    return null
  }

  return (
    <div className="slide absolute top-0 w-full h-full">
      <CaseHeroContent
        slideHeroContent={slideHeroContent}
        isSlideTransition={isSlideTransition}
        index={index}
        activeIndex={activeIndex}
      />

      {muxVideo && (
        <div
          className={cx('h-full w-full transition-opacity', {
            'opacity-0': !isActive,
            'opacity-100': isActive,
          })}
          style={{
            transitionDuration: `${slideTransitionDuration}ms`,
            transitionDelay: `${slideTransitionDuration}ms`,
          }}
        >
          <div className="absolute inset-0 z-[1] bg-pageText transition-opacity duration-200 bg-opacity-50" />
          <MuxVideo
            video={muxVideo}
            showControls={false}
            autoplay={true}
            muted={true}
            loop={true}
          />
        </div>
      )}
    </div>
  )
}

const HeroCaseVideoCarouselDot = ({
  changeSlide,
  index,
  activeIndex,
}: HeroCaseVideoCarouselDotProps) => {
  const slideIndex = index + 1
  const text = slideIndex < 10 ? `0${slideIndex}` : slideIndex

  const selectSlide = () => {
    if (activeIndex !== index) {
      changeSlide(index)
    }
  }

  return (
    <li
      key={`hero-video-slider-navigation-item-${index}`}
      className="relative border-b-2 border-transparent transition-all duration-300"
    >
      {activeIndex === index && (
        <span className="absolute left-0 bottom-[-2px] right-0 h-[2px] bg-white bg-opacity-30 z-10" />
      )}

      <button
        className="font-semibold text-sm sm:text-base md:text-lg"
        onClick={selectSlide}
      >
        {text}
      </button>

      {activeIndex === index && (
        <span
          className="absolute left-0 bottom-[-2px] right-0 h-[2px] bg-white z-20 scale-x-0"
          style={{
            animation: `fill ${slideDuration / 1000}s linear`,
          }}
        />
      )}
    </li>
  )
}

const HeroCaseVideoCarousel = ({ items }: HeroCaseVideoCarouselProps) => {
  const { heroCaseVideoCarouselDisabled } = useContext(PageHeroContext)

  const sliderRef = useRef<HTMLElement>(null)
  const slideTransitionTimeoutIdRef = useRef<NodeJS.Timeout>()

  const [activeIndex, setActiveIndex] = useState(0)
  const [isSlideTransition, setIsSlideTransition] = useState(false)

  const changeSlide = useCallback((index: number) => {
    setActiveIndex(index)

    // Disable previous transition's ending from altering state
    if (slideTransitionTimeoutIdRef.current) {
      clearTimeout(slideTransitionTimeoutIdRef.current)
    }

    // Initialize slide transition
    setIsSlideTransition(true)
    slideTransitionTimeoutIdRef.current = setTimeout(() => {
      setIsSlideTransition(false)
      slideTransitionTimeoutIdRef.current = undefined
    }, slideTransitionDuration)
  }, [])

  // Automatic slide change after a timeout
  useEffect(() => {
    if (heroCaseVideoCarouselDisabled) {
      return
    }

    const autoplayTimeoutId = setTimeout(() => {
      const nextIndex = (activeIndex + 1) % items.length
      changeSlide(nextIndex)
    }, slideDuration)

    return () => {
      clearTimeout(autoplayTimeoutId)
    }
  }, [activeIndex, heroCaseVideoCarouselDisabled, changeSlide, items.length])

  // Transition delay for content
  useEffect(() => {
    if (heroCaseVideoCarouselDisabled) {
      return
    }

    const slideElements = sliderRef.current?.querySelectorAll('.slide')
    const activeSlideElement = slideElements?.[activeIndex]
    const activeSlideContentElements = activeSlideElement?.querySelectorAll(
      '.rc > *'
    ) as NodeListOf<HTMLElement> | undefined

    // Update transition delay of active slide content
    activeSlideContentElements?.forEach((element, index) => {
      element.style.transitionDelay = `${
        index * (slideTransitionDuration / 10)
      }ms`
    })
  }, [activeIndex, heroCaseVideoCarouselDisabled])

  return (
    <section
      ref={sliderRef}
      className={cx(
        'relative overflow-hidden bg-pageText text-white',
        'h-screen max-h-[100svh]',
        {
          // Should hero size remain unchanged when opening a case? Previously when opening the case page,
          // it changed from 100vh to a specific aspect ratio, like so:
          // 'h-screen max-h-[100svh]': !heroCaseVideoCarouselDisabled,
          // 'max-h-screen': heroCaseVideoCarouselDisabled,
        }
      )}
    >
      {items.map((item, index) => (
        <HeroCaseVideoCarouselItem
          key={`hero-case-video-carousel-item-${index}`}
          muxVideo={item.casePage?.heroModule?.muxVideo}
          slideHeroContent={item.content}
          isSlideTransition={isSlideTransition}
          index={index}
          activeIndex={activeIndex}
        />
      ))}

      <AnimatePresence initial={false} exitBeforeEnter>
        {!heroCaseVideoCarouselDisabled && (
          <m.ul
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeWithSlideAnimation}
            className="px-4 sm:container absolute bottom-4 sm:bottom-10 lg:bottom-14 inset-x-0 text-white flex flex-nowrap gap-x-3 z-20"
          >
            {items.map((_, index) => (
              <HeroCaseVideoCarouselDot
                key={`hero-case-video-carousel-dot-${index}`}
                changeSlide={changeSlide}
                index={index}
                activeIndex={activeIndex}
              />
            ))}
          </m.ul>
        )}
      </AnimatePresence>
    </section>
  )
}

export default HeroCaseVideoCarousel
